<template>
  <div>
    <validation-observer v-slot="{ invalid, validate }" ref="entityFormObserver">
      <b-form class="p-2" autocomplete="off" @submit.prevent="validate().then(submit)">
        <slot :validate="validate" />

        <div v-if="editMode" class="mb-3 text-right">
          <template v-for="extraAction in extraActions">
            <b-button v-if="extraActionShouldRender(extraAction)" :key="extraAction.title"
              :variant="extraAction.variant ? extraAction.variant : 'info'" :disabled="disableActions"
              :href="extraAction.href ? extraAction.href : null" class="mr-1" target="_blank"
              @click="handleExtraActionClick(extraAction)">
              {{ $t(extraAction.title) }}
            </b-button>
          </template>

          <!-- Action Buttons -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="disableActions || disableSave || invalid" variant="primary"
            class="mr-sm-1" type="submit">
            {{ $t('Save') }}
          </b-button>
        </div>
        <b-alert :show="customValidationMsg.length > 0" variant="danger" class="mb-10">
          <div class="alert-body">
            {{ customValidationMsg }}
          </div>
        </b-alert>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { BAlert, BButton, BForm } from 'bootstrap-vue'
import axios from '@/libs/axios'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { isSeller } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import moment from 'moment-timezone';


export default {
  components: {
    BAlert,
    BButton,
    BForm,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    extraValidation: {
      type: Function,
      default: null,
      required: false,
    },
    submitCallback: {
      type: Function,
      default: null,
      required: false,
    },
    extraActions: {
      type: Array,
      default: null,
      required: false,
    },
    editMode: {
      type: Boolean,
      default: true,
      required: false,
    },
    disableSave: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { refFormObserver, getValidationState } = formValidation(
      props.clearEntityData
    )

    return {
      refFormObserver,
      getValidationState,
    }
  },
  data() {
    return {
      customValidationMsg: '',
      disableActions: false,
    }
  },
  computed: {
    isSeller() {
      return isSeller()
    },
    config() {
      return this.$store.getters[`${this.module}/config`]
    },
  },
  methods: {
    addEntity(data) {
      this.saveEntity('add', data)
    },
    updateEntity(data) {
      this.saveEntity('update', data)
    },
    saveEntity(saveType, data) {
      const convertToAthensISO = (dateString) => {
      // Parse the date string as per Athens timezone
      const athensDate = moment.tz(dateString, 'Europe/Athens');
      
      // Convert the date to ISO string
      const isoString = athensDate.toISOString();
      
      return isoString;
    };
      const payload = data
      this.disableActions = true
      if (this.config.endpoint === 'subscriptions' && saveType === 'update') {
      const subscriptionId = payload.id;
      const subscriptionEndDateISO = payload.endDate ? convertToAthensISO(payload.endDate) : null;

      axios.put(`/subscriptions/${subscriptionId}/end-date`, { endDate: subscriptionEndDateISO }, {
        headers: {
          'Content-Type': 'application/json',
        }
    })
      .then((response) => {
        // Handle the response if needed
        this.handleEntitySave(response);

      })
      .catch((error) => {
        this.handleSaveError(error);
      })
      .finally(() => {
        this.disableActions = false;
      });

      // Return to prevent further execution of default save logic
      return;
  } 
      if(this.config.endpoint == 'products/deals' && payload.dealProvider==='Supplier'){
        payload.productPrice.seller = payload.supplier;
      }
      if(this.config.endpoint == 'users'){
        payload.userGroup = this.entity.activeUserGroup ? this.entity.activeUserGroup : this.entity.userGroup ;
      }
      if (this.config.endpoint === 'user-groups' && this.config.route === 'deal-subscriptions') {
        payload.category = 'DEAL';
      }
      if (this.config.endpoint === 'user-groups' && this.config.route === 'user-groups') {
        payload.category = 'PRODUCT';
      }
      this.$store
        .dispatch(`${this.config.endpoint}/${saveType}Entity`, payload)
        .then((response) => {
          if (this.config.endpoint == 'products' || this.config.endpoint == 'products/deals')
            this.restructureResponse(response)
          this.handleEntitySave(response)
          this.disableActions = false
        })
        .catch((error) => {
          this.handleSaveError(error)
          this.disableActions = false
        })
    },
    showToast(toastProps) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: toastProps,
      })
    },
    handleEntitySave(response) {
      this.processEntitySave(
        response,
        this.$t('Entity Saved'),
        this.$t('Save completed successfully')
      )
    },
    processEntitySave(response, msgTitle, msgText) {
      if (response.data) {
        this.$parent.$parent.entity = response.data
        this.showToast({
          title: msgTitle,
          icon: 'CheckIcon',
          variant: 'success',
          text: msgText,
        });
        if (this.config && this.config.route === 'product-categories' && this.$route && !this.$route.params.id) {
          router.go();
        }
      } else if (response.status === 200) {
    // Handle scenarios where there is no data property but the response has a 200 status
          this.showToast({
            title: msgTitle,
            icon: 'CheckIcon',
            variant: 'success',
            text: msgText,
          });
        } else {
        const error = { message: this.$t('An unexpected error occurred') }
        this.handleSaveError(error)
      }
    },
    handleSaveError(error) {
      this.showToast({
        title: this.$t('Save Failure'),
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        text: error.message,
      })
    },
    async submit() {
      let isValid = await this.$refs.entityFormObserver.validate()
      if (this.extraValidation) {
        this.customValidationMsg = this.extraValidation()
        isValid = this.customValidationMsg.length === 0
      }
      if (isValid) {
        if (this.entity.id) {
          if (this.module == 'products' || this.module == 'products/deals') this.restructureRequest()
          //sellerUpdate value set true when seller updates a product 
          if (isSeller) {
            this.entity.sellerUpdate = true
          }
          if (this.module == 'products/deals') {
            const payload = this.entity
            const originalDealEndDate = payload?.dealEndDate;
            const originalFreeBonusEndDate = payload?.freeBonusEndDate;
            const dealEndDateISO =originalDealEndDate? new Date(originalDealEndDate).toISOString():null;
            const freeBonusEndDateISO = originalFreeBonusEndDate? new Date(originalFreeBonusEndDate)?.toISOString():null;

            // Update the object with the converted dealEndDate
            const updatedPayload = {
              ...payload,
              dealEndDate: dealEndDateISO,
              freeBonusEndDate: freeBonusEndDateISO
            };
            this.updateEntity(updatedPayload)
          } else {
            this.updateEntity(this.entity)
          }

        } else {
          if (this.module == 'products' || this.module == 'products/deals') this.restructureRequest()
          if (this.module == 'products/deals') {
            const payload = this.entity
            const originalDealEndDate = payload?.dealEndDate;
            const originalFreeBonusEndDate = payload?.freeBonusEndDate;
            const dealEndDateISO =originalDealEndDate? new Date(originalDealEndDate).toISOString():null;
            const freeBonusEndDateISO = originalFreeBonusEndDate? new Date(originalFreeBonusEndDate)?.toISOString():null;

            // Update the object with the converted dealEndDate
            const updatedPayload = {
              ...payload,
              dealEndDate: dealEndDateISO,
              freeBonusEndDate: freeBonusEndDateISO
            };
            this.addEntity(updatedPayload)
          } else {
            this.addEntity(this.entity)
          }


        }

        this.$nextTick(() => {
          this.$refs.entityFormObserver.reset()
          if (this.submitCallback) {
            this.submitCallback()
          }
        })
      }
    },

    extraActionShouldRender(action) {
      if (
        this.entity.id &&
        // If no authority validation is given or the validation passes continue
        // eslint-disable-next-line no-prototype-builtins
        (!action.hasOwnProperty('authorityValidation') ||
          action.authorityValidation) &&
        // If no render condition is given or the key matches the desired value continue
        (!action.renderCondition ||
          (action.renderCondition.exists &&
            this.entity[action.renderCondition.key] > 0) ||
          this.entity[action.renderCondition.key] ===
          action.renderCondition.value)
      ) {
        return true
      }

      return false
    },

    async handleExtraActionClick(action) {
      if (!action.href) {
        try {
        let response = null
        this.disableActions = true
        if (action.callback === 'updateStockPrice') {
          response = await axios.put(
            `${this.config.endpoint}/${action.endpoint}`,
            {
              id: this.entity.id,
              regularPrice: this.entity.regularPrice,
              quantity: this.entity.quantity,
            }
          )
        } else if (action.callback === 'updateEshop') {
          const shouldUpdateEshop =
            require('@/components/shouldUpdateEshop').default

          if (shouldUpdateEshop(this.entity)) {
            response = await axios.get(
              `${this.config.endpoint}/${action.endpoint}/${this.entity.id}`
            )
          } else {
            response = await axios.put(
              `${this.config.endpoint}/${action.endpoint}`,
              {
                id: this.entity.id,
                regularPrice: this.entity.regularPrice,
                quantity: this.entity.quantity,
              }
            )
          }
        } else if (action.route === 'user-groups') {
          response = await axios.put(
            `${this.config.endpoint}/${this.entity.id}/${action.endpoint}?value=${action.actionValue}`,           
          )
          if (response.status === 200) {
            const firstGetResponse = await axios.get(
            `${this.config.endpoint}/${this.entity.id}`,           
            );
            const secondGetResponse = await axios.get(
            `/users/usergrouprights/${this.entity.id}`,           
            );
            firstGetResponse.data.userGroupRights = secondGetResponse.data;

            // Store the modified firstGetResponse in the response variable
            response = firstGetResponse;
            this.$router.push({ name: 'user-groups-list' })      

          }
        }
        else if (action.route === 'deal-subscriptions') {
          response = await axios.put(
            `${this.config.endpoint}/${this.entity.id}/${action.endpoint}?value=${action.actionValue}`,           
          )
          if (response.status === 200) {
            response = await axios.get(
            `${this.config.endpoint}/${this.entity.id}`,           
            )
            this.$router.push({ name: 'deal-subscriptions-list' })      
          }
        }
        else {
          response = await axios.get(
            `${this.config.endpoint}/${action.endpoint}/${this.entity.id}`
          )
          if (this.config.endpoint == 'products' || this.config.endpoint == 'products/deals')
            this.restructureResponse(response)
        }

        this.disableActions = false
        this.processEntitySave(
          response,
          this.$t('Action completed'),
          this.$t('Action completed successfully')
        )
      } catch (error) {
       this.disableActions = false
        }
      }
    },
    restructureRequest() {
      //for restructuring request before submit
      let productCategoriesAndAttributes = {}
      let attributeWithSub = {}
      productCategoriesAndAttributes.category = this.entity.category
      if (this.entity.subCategory !== '' && this.entity.subCategory !== null)
        productCategoriesAndAttributes.subCategory = this.entity.subCategory
      Object.entries(this.entity).forEach((item) => {
        if (!isNaN(parseInt(item[0]))) {
          attributeWithSub[item[0]] = item[1]
          // delete this.entity[item[0]]
        }
      })
      // delete this.entity.category
      // delete this.entity.subCategory
      productCategoriesAndAttributes.attributeWithSub = attributeWithSub
      // console.log('restructureRequest() productCategoriesAndAttributes:', [
      //   productCategoriesAndAttributes,
      // ])
      this.entity.productCategoriesAndAttributes = [
        productCategoriesAndAttributes,
      ]
      // console.log('restructureRequest() this.entity:', this.entity)
    },
    restructureResponse(response) {
      //for restructuring response after submit or eshop publish/update
      const { category, subCategory, attributeWithSub } =
        response.data.productCategoriesAndAttributes[0]
      // console.log(
      //   'restructureResponse() get res.data.productCategoriesAndAttributes:',
      //   response.data.productCategoriesAndAttributes
      // )
      response.data = {
        ...response.data,
        category,
        subCategory,
        ...attributeWithSub,
      }
      // console.log(
      //   'restructureResponse() get response after restructuring:',
      //   response
      // )
    },
  },
}
</script>
