import axios from '@/libs/axios'

export default function useGenericEntityStore(moduleEndpoint) {
  const storeModule = {
    namespaced: true,
    state: {
      module: '',
      endpoint: '',
      route: '',
      title: {
        single: '',
        plural: '',
      },
    },
    getters: {
      config: state => ({
        module: state.module,
        endpoint: state.endpoint,
        route: state.route,
        title: {
          single: state.title.single,
          plural: state.title.plural,
        },
      }),
    },
    mutations: {
      setConfig(state, payload) {
        state.module = payload.module
        state.endpoint = payload.endpoint
        state.route = payload.route
        state.title.single = payload.title.single
        state.title.plural = payload.title.plural
      },
    },
    actions: {
      getEntities(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get(`${moduleEndpoint}/search`, { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      getEntity(ctx, { id }) {
        return new Promise((resolve, reject) => {
          axios
            .get(`${moduleEndpoint}/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addEntity(ctx, entityData) {
        return new Promise((resolve, reject) => {
          axios
            .post(moduleEndpoint, entityData)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      updateEntity(ctx, entityData) {
        return new Promise((resolve, reject) => {
          axios
            .put(moduleEndpoint, entityData)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      deleteEntity(ctx, { id }) {
        return new Promise((resolve, reject) => {
          axios
            .delete(`${moduleEndpoint}/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      downloadReport(ctx, payload) {
        return new Promise((resolve, reject) => {
          axios
            .get(`${moduleEndpoint}/${payload.format}`, { responseType: 'arraybuffer', params: payload.params })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
    },
  }
  return {
    storeModule,
  }
}
