<template>
  <div>
    <EntityList
      ref="orderPaymentsList"
      :config="config"
      :columns="columns"
      :custom-query="{order: parentEntity.id}"
      :formatted-columns="formattedColumns"
      :form-fields="formFields"
      authority="Orders_Payment"
      embedded
    />
  </div>
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from '../orderPaymentsStoreModule'

export default {
  components: {
    EntityList,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    parentEntity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paymentMethodOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'order-payments',
        endpoint: 'order-payments',
        route: 'order-payments',
        title: {
          single: this.$t('Payment Add'),
          plural: this.$t('Payments'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'amount', label: this.$t('Amount'), sortable: true },
        { key: 'paymentMethod.title', label: this.$t('Payment Method'), sortable: false },
        { key: 'paymentDate', label: this.$t('Payment Date'), sortable: true },
        { key: 'creationDate', label: this.$t('Creation Date'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'paymentDate', type: 'datetime' },
        { key: 'creationDate', type: 'datetime' },
      ]
    },
    formFields() {
      return [
        {
          id: 'orderId',
          type: 'hidden',
          initVal: this.parentEntity.id,
          hidden: true,
        },
        {
          id: 'amount',
          type: 'text',
          label: this.$t('Paid Amount'),
          required: true,
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'paymentMethod',
          object: true,
          type: 'select',
          label: this.$i18n.t('Payment Method'),
          options: this.paymentMethodOptions,
          required: true,
        },
        {
          id: 'paymentDate',
          type: 'datetime',
          label: this.$i18n.t('Payment Date'),
          required: true,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: false,
        },
        {
          id: 'comments',
          type: 'quill',
          label: this.$t('comments'),
          required: false,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchPaymentMethods')
      .then(response => {
        this.paymentMethodOptions = response.data
      })
  },
}
</script>
