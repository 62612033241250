<template>
  <div>
    <!-- Entity Handler -->
    <EntityHandlerSidebar
      v-if="tableReady && !hideEdit && !reportView && !mapView"
      v-model="isEntityHandlerSidebarActive"
      :module="config.module"
      :entity="entity"
      :duplicate="isEntityDuplicate"
      :clear-entity-data="clearEntityData"
      :add-entity="addEntity"
      :update-entity="updateEntity"
      :form-fields="formFields"
      :size="sidebarSize"
      :import-csv="importCsv"
    />
    <!-- Filters -->
    <b-card-actions
      v-if="filters"
      :title="$t('Filters')"
      :collapsed="!config.filtersOpen"
      :class="{
        'mb-1': config.limited,
      }"
      action-collapse
      no-body
    >
      <b-card-body>
        <b-row>
          <b-col
            v-for="(filter, index) in filters"
            :key="filter.name"
            cols="12"
            md="4"
            class="mb-1"
          >
            <label>{{ filter.label }}</label>
            <div v-if="filter.type === 'date'" class="position-relative">
              <flat-pickr
                v-model="filterVals[index]"
                :config="{
                  dateFormat: 'Y-m-d',
                  minDate: filter.minDateFieldIdx
                    ? filterVals[filter.minDateFieldIdx]
                    : null,
                  maxDate:
                    filter.maxDateFieldIdx && filterVals[filter.maxDateFieldIdx]
                      ? filterVals[filter.maxDateFieldIdx]
                      : filter.maxDate
                      ? filter.maxDate
                      : null,
                  allowInput: false,
                }"
                :placeholder="$t('YYYY-MM-DD')"
                class="form-control flat-pickr-inherit"
                @on-change="updateFilterValues($event, index)"
              />
              <span
                v-if="filterVals[index]"
                class="flat-pickr-clear"
                @click="updateFilterValues(null, index)"
              >
                x
              </span>
            </div>
            <v-select
              v-else-if="filter.type === 'boolean'"
              :value="filterVals[index]"
              :options="getBooleanOptions"
              :reduce="(val) => val.id"
              label="name"
              class="w-100"
              append-to-body
              clearable
              @input="updateFilterValues($event, index)"
            />
            <v-select
              v-else-if="filter.options"
              :value="filterVals[index]"
              :options="filter.options"
              :reduce="(val) => val.id"
              :clearable="!filter.value"
              label="title"
              class="w-100"
              filterable
              append-to-body
              @input="updateFilterValues($event, index)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card-actions>

    <b-alert
      v-if="config.limited"
      :variant="remainingEntries < 1 ? 'danger' : 'secondary'"
      show
      class="p-1"
    >
      {{
        $t('RemainingEntries', {
          remaining: remainingEntries,
          limit: config.limited,
        })
      }}
    </b-alert>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="4"
            lg="3"
            xl="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-xl-0"
          >
            <b-button-group class="w-100">
              <json-excel
                :fetch="fetchAllEntities"
                :fields="exportFields"
                :name="config.title.plural + '.xls'"
                class="w-50"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="w-100 right-radius-reset"
                >
                  Excel
                </b-button>
              </json-excel>
              <json-excel
                :fetch="fetchAllEntities"
                :fields="exportFields"
                :name="config.title.plural + '.csv'"
                :escape-csv="false"
                type="csv"
                class="w-50"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="w-100 left-radius-reset"
                >
                  CSV
                </b-button>
              </json-excel>
            </b-button-group>
          </b-col>
          <!-- Per Page -->
          <b-col
            v-if="!mapView"
            cols="12"
            md="8"
            lg="3"
            xl="3"
            class="d-flex align-items-center justify-content-end mb-1 mb-xl-0"
          >
            <label>{{ $t('Entries') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              append-to-body
              class="per-page-selector d-inline-block ml-50 mr-lg-50"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="12" lg="12" xl="7">
            <b-row class="d-flex align-items-center justify-content-end">
              <b-col
                :md="showHeadRowButton ? 8 : 12"
                cols="12"
                class="mb-1 mb-md-0"
              >
                <b-form-input
                  v-if="!reportView"
                  :placeholder="$t('Search') + '...'"
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                />
              </b-col>

              <b-col v-if="showHeadRowButton" cols="12" md="4">
                <b-button
                  v-if="config.limited && remainingEntries < 1"
                  :href="eshopUrl + '/mpu-subscriptions'"
                  variant="success"
                  class="w-100"
                  target="_blank"
                >
                  <span class="text-nowrap">{{ $t('Upgrade') }}</span>
                </b-button>
                <b-button
                  v-else-if="!reportView && allowInsert && hasInsertBtn"
                  :to="getCreateLink()"
                  variant="primary"
                  class="w-100"
                  @click="handleAddClick()"
                >
                  <span class="text-nowrap">
                    {{ $t('Add') }} {{ config.title.single }}
                  </span>
                </b-button>
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-else-if="reportView"
                  :text="$t('Download full report')"
                  :disabled="downloadingFile"
                  class="w-100 ml-md-1"
                  right
                  variant="primary"
                >
                  <b-dropdown-item
                    v-for="format in exportFileFormats"
                    :key="format"
                    @click="downloadFile(format)"
                  >
                    {{ format.toUpperCase() }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <div v-if="mapView">
        <GmapMap
          v-if="tableReady"
          ref="refEntitiesMap"
          :center="center"
          :zoom="13"
          map-type-id="roadmap"
        >
          <GmapInfoWindow
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          />
          <GmapMarker
            v-for="(item, index) in entities"
            :key="'marker' + item.id"
            :position="{ lat: item.lat, lng: item.lng }"
            :animation="animation"
            @click="toggleInfoWindow(item, index)"
          />
          <GmapCircle
            v-for="item in entities"
            :key="'circle' + item.id"
            :center="{ lat: item.lat, lng: item.lng }"
            :radius="item.serviceRadius"
            :options="circleOptions"
            :draggable="false"
            :editable="false"
          />
        </GmapMap>
      </div>
      <div v-else>
        <b-table
          v-if="tableReady"
          ref="refEntitiesTable"
          :empty-text="$i18n.t('No matching records found')"
          :items="fetchEntities"
          :fields="columns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative entity-table"
          responsive       
          primary-key="id"
          show-empty
        >
          <!-- Column: name -->
          <template #cell(title)="data">
            <b-link
              v-if="allowEdit && !hideEdit"
              :to="getEditLink(data.item.id)"
              class="font-weight-bold d-block"
              @click="handleEntityClick(data.item)"
            >
              {{ data.item.title }}
            </b-link>
            <span v-else>
              {{ data.item.title }}
            </span>
          </template>
          <template
            v-for="field in formattedColumns"
            #[`cell(${field.key})`]="data"
          >
            <feather-icon
              v-if="field.type == 'boolean'"
              :key="field.key"
              :icon="data.item[field.key] ? 'CheckIcon' : 'XIcon'"
              size="18"
            />
            <b-img
              v-else-if="field.type == 'image' && data.item[field.key]"
              :key="field.key + '_img'"
              :src="data.item[field.key]"
              :width="field.width ? field.width : 100"
              alt=""
              thumbnail
            />
            <b-img
              v-else-if="field.type == 'logo' && data.item[field.key]"
              :key="field.key + '_logo'"
              :src="data.item[field.key]"
              :width="field.width ? field.width : 100"
              alt=""
              rounded="circle"
            />
            <avatar-cell
              v-else-if="field.type == 'seller' || field.type == 'supplier'"
              :id="`entity-row-${data.item.id}`"
              :key="field.key + '_seller'"
              :logo="data.item[field.type].logo"
              :url="data.item[field.type].eshopSlug"
              :title="data.item[field.type].title"
              :subtitle="data.item[field.type].perioxh"
              :base-url="field.type"
            />
            <avatar-cell
              v-else-if="field.type == 'partner-sender'"
              :id="`entity-row-${data.item.id}`"
              :key="field.key + '_send'"
              :logo="data.item.senderLogo"
              :url="data.item.senderUrl"
              :title="data.item.sender"
              :subtitle="data.item.senderCompany"
            />
            <avatar-cell
              v-else-if="field.type == 'partner-receiver'"
              :id="`entity-row-${data.item.id}`"
              :key="field.key + '_receive'"
              :logo="data.item.receiverLogo"
              :url="data.item.receiverUrl"
              :title="data.item.receiver"
              :subtitle="data.item.receiverCompany"
            />
            <b-avatar
              v-else-if="field.type == 'status'"
              :id="`entity-row-${data.item.id}`"
              :key="field.key + '_status'"
              :variant="`light-${
                resolveStatusVariantAndIcon(data.item[field.key]).variant
              }`"
              size="32"
            >
              <feather-icon
                :icon="resolveStatusVariantAndIcon(data.item[field.key]).icon"
              />

              <b-tooltip
                v-if="
                  data.item[field.key] === true ||
                  data.item[field.key] === false
                "
                :target="`entity-row-${data.item.id}`"
                placement="top"
              >
                <p class="mb-0">
                  {{
                    data.item[field.key] === true
                      ? $t('Approved')
                      : $t('Pending')
                  }}
                </p>
              </b-tooltip>
            </b-avatar>
            <span
              v-else-if="field.type == 'translate'"
              :key="field.key + '_translate'"
              :class="{ 'subscription-active': data.item[field.key] === 'ACTIVE' }"
            >
              {{ $t(data.item[field.key]) }}
            </span>
            <span
              v-for="(innerItem, index) in data.item[field.key]"
              v-else-if="field.type == 'list'"
              :key="innerItem.id"
            >
              {{
                innerItem.title +
                (index == data.item[field.key].length - 1 ? '' : ', ')
              }}
            </span>
            <template v-else-if="field.type == 'date'">
              {{ formatDate(data.item[field.key]) }}
            </template>
            <template v-else-if="field.type == 'daymonth'">
              {{
                formatDate(data.item[field.key], {
                  month: 'long',
                  day: 'numeric',
                })
              }}
            </template>
            <template v-else-if="field.type == 'datetime'">
              {{
                formatDate(data.item[field.key], {
                  dateStyle: 'medium',
                  timeStyle: 'medium',
                  hour12: false,
                })
              }}
            </template>
            <template v-else-if="field.type == 'price'">
              {{ formatPrice(data.item[field.key]) }}
            </template>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <template v-if="inlineActions">
              <div class="w-100px">
                <b-button
                  v-b-tooltip.hover
                  v-if="detailsView"
                  :title="$t('Details')"
                  :to="getViewLink(data.item.id)"
                  variant="primary"
                  size="sm"
                  class="mr-50 mb-25"
                >
                  <feather-icon icon="FileTextIcon" />
                </b-button>

                <b-button
                  v-b-tooltip.hover
                  v-if="allowEdit && !hideEdit"
                  :title="$t('Edit')"
                  :to="getEditLink(data.item.id)"
                  variant="primary"
                  size="sm"
                  class="mr-50 mb-25"
                  @click="handleEntityClick(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  v-b-tooltip.hover
                  v-if="allowEdit && allowDuplicate"
                  :title="$t('Duplicate')"
                  :to="getCopyLink(data.item.id)"
                  variant="secondary"
                  size="sm"
                  class="mr-50 mb-25"
                  @click="handleDuplicateClick(data.item)"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>

                <template v-for="extraAction in extraActions">
                  <b-button
                    v-b-tooltip.hover
                    v-if="extraActionShouldRender(extraAction, data.item)"
                    :key="extraAction.endpoint"
                    :title="$t(extraAction.title)"
                    :disabled="extraAction.disabled"
                    :variant="
                      extraAction.variant ? extraAction.variant : 'info'
                    "
                    :href="
                      extraAction.href
                        ? extraAction.href +
                          (extraAction.appendField
                            ? data.item[extraAction.appendField]
                            : '')
                        : null
                    "
                    size="sm"
                    class="mr-50 mb-25"
                    target="_blank"
                    @click="handleExtraActionClick(data.item, extraAction)"
                  >
                    <feather-icon :icon="extraAction.icon" />
                  </b-button>
                </template>

                <b-button
                  v-b-tooltip.hover
                  v-if="enableDisableToggle"
                  :title="$t(data.item.enabled ? 'Disable' : 'Enable')"
                  :variant="data.item.enabled ? 'success' : 'warning'"
                  :disabled="disableActions"
                  size="sm"
                  class="mr-50 mb-25"
                  @click="toggleEnableDisable(data.item)"
                >
                  <feather-icon
                    :icon="
                      data.item.enabled ? 'ToggleRightIcon' : 'ToggleLeftIcon'
                    "
                  />
                </b-button>

                <b-button
                  v-b-tooltip.hover
                  v-if="allowDelete && !hideDelete"
                  :title="$t('Delete')"
                  variant="danger"
                  size="sm"
                  class="mr-50 mb-25"
                  @click="openDeleteModal(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </template>

            <b-dropdown v-else variant="link" no-caret>
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="detailsView"
                :to="getViewLink(data.item.id)"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">{{ $t('Details') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="allowEdit && !hideEdit"
                :to="getEditLink(data.item.id)"
                @click="handleEntityClick(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="allowEdit && allowDuplicate"
                :to="getCopyLink(data.item.id)"
                @click="handleDuplicateClick(data.item)"
              >
                <feather-icon icon="CopyIcon" />
                <span class="align-middle ml-50">{{ $t('Duplicate') }}</span>
              </b-dropdown-item>

              <template v-for="extraAction in extraActions">
                <b-dropdown-item
                  v-if="extraActionShouldRender(extraAction, data.item)"
                  :key="extraAction.title"
                  :disabled="disableActions"
                  :href="
                    extraAction.href
                      ? extraAction.href +
                        (extraAction.appendField
                          ? data.item[extraAction.appendField]
                          : '')
                      : null
                  "
                  target="_blank"
                  @click="handleExtraActionClick(data.item, extraAction)"
                >
                  <feather-icon :icon="extraAction.icon" />
                  <span class="align-middle ml-50">{{
                    $t(extraAction.title)
                  }}</span>
                </b-dropdown-item>
              </template>

              <b-dropdown-item
                v-if="enableDisableToggle"
                :disabled="disableActions"
                @click="toggleEnableDisable(data.item)"
              >
                <feather-icon
                  :icon="
                    data.item.enabled ? 'ToggleRightIcon' : 'ToggleLeftIcon'
                  "
                />
                <span class="align-middle ml-50">{{
                  $t(data.item.enabled ? 'Disable' : 'Enable')
                }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="allowDelete"
                @click="openDeleteModal(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{
                $t('ShowingEntries', {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  entries: dataMeta.of,
                })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
          <b-modal v-model="showDeleteModal" title="Επιβεβαίωση διαγραφής" class="delete-modal" centered @ok="handleDeleteClick(itemToDelete)" >
            <p class="delete-modal-content"> Είστε σίγουρος ότι θέλετε να διαγράψετε οριστικά το <strong>{{ itemToDelete&&itemToDelete.title }}</strong>?</p>
          </b-modal>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAlert,
  BImg,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BButtonGroup,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BLink,
  VBTooltip,
  BTooltip,
  BAvatar,
  BModal,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import JsonExcel from 'vue-json-excel'
import Ripple from 'vue-ripple-directive'
import { formatDate, formatPrice } from '@core/utils/filter'
import { getUserRights } from '@/auth/utils'
import EntityHandlerSidebar from '@/layouts/entity/EntityHandlerSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AvatarCell from './AvatarCell.vue'
import { mapMutations } from 'vuex';

export default {
  components: {
    BAlert,
    BImg,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BButtonGroup,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,
    vSelect,
    flatPickr,
    EntityHandlerSidebar,
    JsonExcel,
    BTooltip,
    BAvatar,
    BCardActions,
    AvatarCell,
    BModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        store: null,
        module: 'example',
        endpoint: 'example',
        route: 'example',
        title: {
          single: 'Example',
          plural: 'Examples',
        },
        limited: null,
      }),
    },
    columns: {
      type: Array,
      default() {
        return [
          { key: 'id', label: this.$t('id'), sortable: true },
          { key: 'name', label: this.$t('name'), sortable: true },
          { key: 'description', label: this.$t('description'), sortable: true },
          { key: 'actions', label: this.$t('actions') },
        ]
      },
      required: false,
    },
    mapView: {
      type: Boolean,
      default: false,
      required: false,
    },
    detailsView: {
      type: Boolean,
      default: false,
      required: false,
    },
    reportView: {
      type: Boolean,
      default: false,
      required: false,
    },
    singleEdit: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowDuplicate: {
      type: Boolean,
      default: true,
      required: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasInsertBtn: {
      type: Boolean,
      default: true,
      required: false,
    },
    filters: {
      type: Array,
      default: null,
      required: false,
    },
    filterVals: {
      type: Array,
      default: null,
      required: false,
    },
    customQuery: {
      type: Object,
      default: () => {},
      required: false,
    },
    resultsPerPage: {
      type: Number,
      default: 10,
      required: false,
    },
    formFields: {
      type: Array,
      default() {
        return [
          {
            id: 'name',
            type: 'text',
            label: this.$t('name'),
            required: true,
          },
          {
            id: 'description',
            type: 'textarea',
            label: this.$t('Description'),
          },
        ]
      },
      required: false,
    },
    formattedColumns: {
      type: Array,
      default: null,
      required: false,
    },
    authority: {
      type: String,
      default: '',
      required: false,
    },
    extraActions: {
      type: Array,
      default: null,
      required: false,
    },
    inlineActions: {
      type: Boolean,
      default: false,
      required: false,
    },
    importCsv: {
      type: String,
      default: '',
      required: false,
    },
    sidebarSize: {
      type: String,
      default: 'lg',
      required: false,
    },
    enableDisableToggle: {
      type: Boolean,
      default: false,
      required: false,
    },
    descSort: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    return {
      // Filter
      formatDate,
      formatPrice,
    }
  },
  data() {
    return {
      allowInsert: false,
      allowEdit: false,
      allowDelete: false,
      downloadingFile: false,
      isEntityHandlerSidebarActive: false,
      isEntityDuplicate: false,
      blankEntity: null,
      entity: { id: null },
      perPage: 10,
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: !this.descSort,
      entities: [],
      tableReady: false,
      updateFilterDebounce: null,
      disableActions: false,
      center: {
        lat: 37.98400922344468,
        lng: 23.68682929788152,
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      animation: 2,
      showDeleteModal: false,
      itemToDelete: null,
    }
  },
  computed: {
    eshopUrl() {
      return process.env.VUE_APP_SHOP_URL
    },
    remainingEntries() {
      return this.config.limited ? this.config.limited - this.totalRows : null
    },
    extraParams() {
      const params = []
      this.filters.forEach((filter) => {
        params.push(filter.name)
      })
      return params
    },
    exportFields() {
      const jsonFields = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const [, obj] of Object.entries(this.columns)) {
        if (obj.key !== 'actions') {
          jsonFields[obj.label] = obj.key
        }
      }
      return jsonFields
    },
    dataMeta() {
      const localItemsCount = this.$refs.refEntitiesTable
        ? this.$refs.refEntitiesTable.localItems.length
        : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRows,
      }
    },

    exportFileFormats() {
      return ['pdf', 'html', 'xlsx', 'csv', 'xml']
    },

    getBooleanOptions() {
      return [
        { id: null, name: this.$t('All') },
        { id: '1', name: this.$t('Yes') },
        { id: '0', name: this.$t('No') },
      ]
    },

    showHeadRowButton() {
      return (
        (this.config.limited && this.remainingEntries < 1) ||
        this.reportView ||
        (this.allowInsert && this.hasInsertBtn)
      )
    },

    circleOptions() {
      return {
        strokeColor: '#A7844E',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#A7844E',
        fillOpacity: 0.35,
      }
    },
  },
  created() {
    const rights = getUserRights()

    this.allowInsert = rights.includes(`${this.authority}_Create`)
    if(this.authority === 'Users_Payment'){
      this.allowInsert = false;
    }
    this.allowEdit = rights.includes(`${this.authority}_Edit`)
    this.allowDelete = rights.includes(`${this.authority}_Delete`)

    this.perPage = this.resultsPerPage

    if (this.filters) {
      this.filters.forEach((filter, index) => {
        if (filter.value) {
          this.filterVals[index] = filter.value
        }
      })
    }
    // Register module
    if (!this.$store.hasModule(this.config.module)) {
      this.$store.registerModule(this.config.module, this.config.store)
      this.$store.commit(`${this.config.module}/setConfig`, this.config)
    }
  },
  mounted() {
    this.initBlankEntity()
    if(this.$store.getters.getProductCode){
      this.setvalue()
      this.setProductCode(null)
    }
    this.$watch('currentPage', this.refetchData)
    this.$watch('perPage', this.refetchData)
    this.$watch('searchQuery', this.refetchData)
    if (this.mapView) {
      this.fetchAllEntities()
    }
    this.tableReady = true
  },
  beforeDestroy() {
    // if (this.$store.hasModule(this.config.module)) {
    //   this.$store.unregisterModule(this.config.module)
    // }
  },
  methods: {
    ...mapMutations(['setProductCode']),
    setvalue(){
      this.searchQuery=this.$store.getters.getProductCode
    },
    initBlankEntity() {
      this.formFields.forEach((field) => {
        if (field.initVal) {
          this.entity[field.id] = field.initVal
        } else if (field.type === 'select' || field.type === 'selectmultiple') {
          this.entity[field.id] = null
        } else if (
          field.type === 'checkbox' ||
          field.type === 'checkboxgroup'
        ) {
          this.entity[field.id] = false
        } else {
          this.entity[field.id] = ''
        }
      })
      this.blankEntity = JSON.parse(JSON.stringify(this.entity))
    },

    getQueryParams() {
      const queryParams = {
        q: this.searchQuery,
        page: this.currentPage - 1,
        size: this.perPage,
        sort: `${this.sortBy},${this.isSortDirDesc ? 'asc' : 'desc'}`,
      }

      if (this.filters) {
        this.extraParams.forEach((value, index) => {
          if (this.filterVals[index]) {
            queryParams[value] = this.filterVals[index]
          }
        })
      }

      if (this.customQuery) {
        Object.keys(this.customQuery).forEach((key) => {
          queryParams[key] = this.customQuery[key]
        })
      }

      return queryParams
    },

    refetchData() {
      if (this.mapView) {
        this.fetchAllEntities()
      } else {
        this.$refs.refEntitiesTable.refresh()
      }
      this.disableActions = false
    },

    fetchEntities() {
      return this.$store
        .dispatch(`${this.config.endpoint}/getEntities`, this.getQueryParams())
        .then((response) => {
          const items = response.data.content
          this.totalRows = response.data.totalElements
          if(this.config.endpoint === 'products')
            localStorage.setItem('productsAdded', response.data.totalElements)
          this.entities = items || []
          return this.entities
        })
        .catch(() => {
          this.showToast({
            title: `Error fetching ${this.config.title.plural} list`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    },

    fetchAllEntities() {
      const params = {
        ...this.getQueryParams(),
        size: null,
      }

      return this.$store
        .dispatch(`${this.config.endpoint}/getEntities`, params)
        .then((response) => {
          const items = response.data.content
          this.totalRows = response.data.totalElements
          this.entities = items || []
          return this.entities
        })
        .catch(() => {
          this.showToast({
            title: `Error fetching ${this.config.title.plural} list`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    },
    resolveStatusVariantAndIcon(status) {
      if (status === true || status === 'ACTIVE') {
        return { variant: 'success', icon: 'CheckCircleIcon' }
      }
      if (status === 'DECLINED') return { variant: 'danger', icon: 'XIcon' }
      if (status === 'DELETED') {
        return { variant: 'danger', icon: 'Trash2Icon' }
      }

      return { variant: 'warning', icon: 'InfoIcon' }
    },

    getViewLink(itemId) {
      return { name: `${this.config.route}-view`, params: { id: itemId } }
    },

    getCreateLink() {
      if (this.config.insertRoute) {
        return this.config.insertRoute
      }
      return this.singleEdit ? { name: `${this.config.route}-edit` } : '#'
    },

    getEditLink(itemId) {
      return this.singleEdit
        ? { name: `${this.config.route}-edit`, params: { id: itemId } }
        : '#'
    },

    getCopyLink(itemId) {
      return this.singleEdit
        ? { name: `${this.config.route}-edit`, params: { duplicate: itemId } }
        : '#'
    },

    getOriginDuplicateLink(itemId) {
      return this.singleEdit
        ? {
            name: `${this.config.route}-edit`,
            params: { duplicate: itemId, keepOrigin: true },
          }
        : '#'
    },

    extraActionShouldRender(action, item) {
      if (
        // If no authority validation is given or the validation passes continue
        // eslint-disable-next-line no-prototype-builtins
        (!action.hasOwnProperty('authorityValidation') ||
          action.authorityValidation) &&
        // If no render condition is given or the key matches the desired value continue
        (!action.renderCondition ||
          item[action.renderCondition.key] === action.renderCondition.value)
      ) {
        return true
      }

      return false
    },

    showToast(toastProps) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: toastProps,
      })
    },

    updateFilterValues(newVal, index) {
      if (this.updateFilterDebounce) return

      this.filterVals[index] = newVal

      this.updateFilterDebounce = setTimeout(() => {
        this.refetchData()
        this.updateFilterDebounce = null
      }, 100)
    },

    clearEntityData() {
      this.entity = JSON.parse(JSON.stringify(this.blankEntity))
    },

    handleAddClick() {
      if (!this.singleEdit) {
        this.isEntityHandlerSidebarActive = true
      }
    },

    handleEntityClick(entityData) {
      if (!this.singleEdit) {
        this.isEntityDuplicate = false
        this.entity = JSON.parse(JSON.stringify(entityData))
        this.isEntityHandlerSidebarActive = true
      }
    },

    handleDuplicateClick(entityData) {
      if (!this.singleEdit) {
        this.isEntityDuplicate = true
        this.entity = JSON.parse(JSON.stringify(entityData))
        this.isEntityHandlerSidebarActive = true
      }
    },

    handleDeleteClick(id) {
      this.$store
        .dispatch(`${this.config.endpoint}/deleteEntity`, id)
        .then(() => {
          this.refetchData()
          this.showDeleteModal = false;
        })
        .catch(() => {
          this.showToast({
            title:
              'The entity could not be deleted! Check the foreign key constraints first.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
          this.showDeleteModal = false;
        })
    },
    openDeleteModal(item) {
      // Open the delete confirmation modal
      this.itemToDelete = item;
      this.showDeleteModal = true;
    },

    async handleExtraActionClick(item, action) {
      if (!action.href) {
        this.disableActions = true
        if (action.callback) {
          if (action.callback === 'getEditLink') {
            this.$router.push(this.getEditLink(item.id))
          } else if (action.callback === 'relationDuplicate') {
            this.$router.push(this.getOriginDuplicateLink(item.id))
          } else if (action.callback === 'submitSellRequest') {
            await axios.get(
              `${this.config.route}/suppliers/sell-request/${item.id}`
            )
            this.$router.push(this.getEditLink(item.id))
          } else if (action.callback === 'updateStockPrice') {
            await axios.put(`${this.config.endpoint}/${action.endpoint}`, {
              id: item.id,
              regularPrice: item.regularPrice,
              quantity: item.quantity,
            })
          } else if (action.callback.includes('updatePartnerStatus')) {
            const status = action.callback.split('_')[1]

            const data = { ...item }
            data.status = status
            axios
              .put(this.config.actionsEndpoint, data)
              .then(() => {
                this.refetchData()
              })
              .catch((error) => {
                this.handleSaveError(error)
              })
          }
        } else {
          await axios.get(
            `${this.config.endpoint}/${action.endpoint}/${item.id}`
          ).then((response) => {

          }).catch((error) => {
            if (action.endpoint === 'partners/request') {
              this.showToast({
            title: 'Cannot send partner request!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response.data.params[0]
          })
            } else if (action.endpoint === 'lead') {
              this.showToast({
            title: 'Cannot add Lead!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response.data.params[0]
          })
            } else if (action.endpoint === 'users') {
              this.showToast({
            title: 'Cannot add User!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response.data.params[0]
          })
            } else 
              this.handleSaveError(error)
          })
        }
        this.refetchData()
      }
    },

    async toggleEnableDisable(item) {
      this.disableActions = true
      await axios.put(`${this.config.endpoint}/enabled`, {
        id: item.id,
        enabled: !item.enabled,
      })

      this.refetchData()
    },

    downloadFile(format) {
      this.downloadingFile = true

      const payload = { format, params: this.getQueryParams() }

      this.$store
        .dispatch(`${this.config.endpoint}/downloadReport`, payload)
        .then((response) => {
          const fileName = `${
            this.config.title.single
          }_${new Date().toISOString()}.${format}`
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          this.downloadingFile = false
        })
    },

    addEntity(data) {
      this.saveEntity('add', data)
    },

    updateEntity(data) {
      this.saveEntity('update', data)
    },

    saveEntity(saveType, data) {
      this.$store
        .dispatch(`${this.config.endpoint}/${saveType}Entity`, data)
        .then(() => {
          this.handleEntitySave()
        })
        .catch((error) => {
          this.handleSaveError(error)
        })
    },

    handleEntitySave() {
      this.isEntityHandlerSidebarActive = false
      this.refetchData()
    },

    handleSaveError(error) {
      this.showToast({
        title: this.$t('Save Failure'),
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        text: error.message,
      })
    },
    toggleInfoWindow(item, idx) {
      this.infoWindowPos = { lat: item.lat, lng: item.lng }
      this.infoOptions.content = item.title

      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-modal-content{
  font-size: 16px; /* Adjust the font size as needed */
}
@media (min-width: 1024px) {
  .entity-table {
    overflow-x: visible;
    overflow-y: visible;
  }
}

.per-page-selector {
  width: 90px;
}

.flat-pickr-inherit {
  background-color: inherit;
}

.flat-pickr-clear {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 1.2;
  cursor: pointer;
}

.right-radius-reset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.left-radius-reset {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0 !important;
}
</style>
