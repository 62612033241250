<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <!-- Tab: General -->
        <b-tab active>
          <template #title>
            <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('General') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="orderFormFields"
            allow-autofocus
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="PackageIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Products') }}</span>
          </template>
          <b-row>
            <b-col cols="12">
              <RepeaterField
                :module="config.module"
                :title="$t('Product')"
                :field="entity.orderProducts"
                :form-fields="productFormFields"
              />
            </b-col>
          </b-row>
        </b-tab>

        <!-- Tab: Billing -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Billing') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="billingFormFields"
          />
        </b-tab>

        <!-- Tab: Shipping -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="ShoppingBagIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Shipping') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="shippingFormFields"
          />
        </b-tab>

        <!-- Tab: Payments -->
        <b-tab v-if="entity.id > 0" lazy>
          <template #title>
            <feather-icon
              icon="CreditCardIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Payments') }}</span>
          </template>
          <OrderPaymentsList
            :module="config.module"
            :parent-entity.sync="entity"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BRow, BCol, BTab, BTabs, BCard } from 'bootstrap-vue'
import { translationUtils } from '@core/mixins/ui/translations'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import RepeaterField from '@/layouts/entity/RepeaterField.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import OrderPaymentsList from './OrderPaymentsList.vue'

import storeModule from '../ordersStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    RepeaterField,
    OrderPaymentsList,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        orderProducts: [],
      },
      entityLoaded: false,
      countryOptions: [],
      customerOptions: [],
      deliveryMethodOptions: [],
      orderStatusOptions: [],
      productOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'order',
        endpoint: 'order',
        route: 'orders',
        title: {
          single: this.$t('Order'),
          plural: this.$t('Orders'),
        },
      }
    },
    orderFormFields() {
      const formFields = [
        {
          id: 'orderNumber',
          type: 'text',
          label: this.$i18n.t('number'),
          required: false,
          rules: 'integer|max:9',
          colSize: 3,
        },
        {
          id: 'orderStatusId',
          type: 'select',
          label: 'Order Status',
          required: true,
          options: this.orderStatusOptions,
        },
        {
          id: 'customerId',
          type: 'select',
          label: 'Customer',
          required: true,
          options: this.customerOptions,
        },
        {
          id: 'deliveryMethodId',
          type: 'select',
          label: 'Delivery Method',
          rules: '',
          required: true,
          options: this.deliveryMethodOptions,
        },
        {
          id: 'cost',
          type: 'text',
          label: this.$i18n.t('cost'),
          required: false,
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'totalPrice',
          type: 'text',
          label: this.$i18n.t('Total Price'),
          required: true,
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'paidAmount',
          type: 'text',
          label: this.$i18n.t('Paid Amount'),
          required: false,
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'invoiceAmount',
          type: 'text',
          label: this.$i18n.t('Invoice Amount'),
          required: false,
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'deadlineDate',
          type: 'datetime',
          label: this.$i18n.t('deadline date'),
          required: false,
          colSize: 3,
        },
        {
          id: 'expectedDate',
          type: 'datetime',
          label: this.$i18n.t('expected date'),
          required: false,
          colSize: 3,
        },
        // {
        //   id: 'offer',
        //   type: 'checkbox',
        //   label: this.$i18n.t('offer'),
        //   colSize: 3,
        // },
        // {
        //   id: 'enabled',
        //   type: 'checkbox',
        //   label: this.$i18n.t('enabled'),
        //   colSize: 3,
        // },
      ]
      return formFields
    },
    productFormFields() {
      return [
        {
          id: 'productId',
          type: 'select',
          label: this.$t('product'),
          options: this.productOptions,
          required: false,
          colSize: 2,
        },
        {
          id: 'quantity',
          type: 'text',
          label: this.$t('quantity'),
          required: false,
          rules: 'integer',
          colSize: 1,
        },
        {
          id: 'cost',
          type: 'text',
          label: this.$t('cost'),
          required: false,
          rules: 'integer',
          colSize: 1,
        },
        {
          id: 'totalPrice',
          type: 'text',
          label: this.$t('Total Price'),
          required: false,
          rules: 'integer',
          colSize: 1,
        },
        {
          id: 'delivered',
          type: 'checkbox',
          label: this.$t('delivered'),
          colSize: 1,
        },
        {
          id: 'comments',
          type: 'textarea',
          label: this.$t('comments'),
          colSize: 4,
          required: false,
        },
      ]
    },
    billingFormFields() {
      return [
        {
          id: 'billingFirstName',
          type: 'text',
          label: this.$t('First Name'),
          required: false,
          colSize: 3,
        },
        {
          id: 'billingLastName',
          type: 'text',
          label: this.$t('Last Name'),
          required: false,
          colSize: 3,
        },
        {
          id: 'billingCompany',
          type: 'text',
          label: this.$t('Company'),
          required: false,
          colSize: 3,
        },
        {
          id: 'billingCountryId',
          type: 'select',
          label: this.$t('country'),
          options: this.countryOptions,
          required: false,
          colSize: 3,
        },
        {
          id: 'billingCity',
          type: 'text',
          label: this.$t('city'),
          required: false,
          colSize: 3,
        },
        {
          id: 'billingAddressLine1',
          type: 'text',
          label: this.$t('address'),
          required: false,
          colSize: 3,
        },
        {
          id: 'billingAddressLine2',
          type: 'text',
          label: this.$t('second address'),
          required: false,
          colSize: 3,
        },
        {
          id: 'billingZipCode',
          type: 'text',
          label: this.$t('postal code'),
          required: false,
          colSize: 3,
        },
        {
          id: 'billingState',
          type: 'text',
          label: this.$t('state'),
          colSize: 3,
        },
        {
          id: 'billingEmail',
          type: 'email',
          label: this.$t('email'),
          rules: 'email',
          colSize: 3,
        },
        {
          id: 'billingPhone',
          type: 'text',
          label: this.$t('telephone'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'billingMethod',
          type: 'text',
          label: this.$t('billing method'),
          colSize: 3,
        },
        {
          id: 'billingTransactionId',
          type: 'text',
          label: this.$t('transaction id'),
          required: false,
          colSize: 3,
        },
      ]
    },
    shippingFormFields() {
      return [
        {
          id: 'shippingFirstName',
          type: 'text',
          label: this.$t('First Name'),
          required: false,
          colSize: 3,
        },
        {
          id: 'shippingLastName',
          type: 'text',
          label: this.$t('Last Name'),
          required: false,
          colSize: 3,
        },
        {
          id: 'shippingCity',
          type: 'text',
          label: this.$t('city'),
          required: false,
          colSize: 3,
        },
        {
          id: 'shippingAddress',
          type: 'text',
          label: this.$t('address'),
          required: false,
          colSize: 3,
        },
        {
          id: 'shippingZipCode',
          type: 'text',
          label: this.$t('postal code'),
          required: false,
          colSize: 3,
        },
        {
          id: 'shippingCustomerNote',
          type: 'textarea',
          label: this.$t('customer note'),
          colSize: 6,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then((response) => {
      this.countryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCustomers').then((response) => {
      this.customerOptions = response.data
    })
    this.$store.dispatch('app-common/fetchDeliveryMethods').then((response) => {
      this.deliveryMethodOptions = response.data
    })
    this.$store.dispatch('app-common/fetchOrderStatuses').then((response) => {
      this.orderStatusOptions = response.data
    })
    this.$store.dispatch('app-common/fetchProducts').then((response) => {
      this.productOptions = response.data
    })
  },
}
</script>
