<template>
  <div v-if="lat && lng">
    <b-form-group
      v-if="showCircle"
      :label="$t('Service Radius (in meters)')"
    >
      <b-form-input
        :value="serviceRadius"
        type="number"
        @change="setRadius"
      />
    </b-form-group>

    <GmapMap
      :center="center"
      :zoom="13"
      map-type-id="roadmap"
    >
      <GmapMarker
        :position="center"
        :animation="animation"
      />
      <GmapCircle
        v-if="showCircle"
        ref="circle"
        :center="center"
        :radius="serviceRadius"
        :options="circleOptions"
        :draggable="false"
        editable
        @radius_changed="updateRadius"
      />
    </GmapMap>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  props: {
    lat: {
      type: Number,
      default: null,
    },
    lng: {
      type: Number,
      default: null,
    },
    entityRadius: {
      type: Number,
      default: 0, // meters
    },
    entity: {
      type: Object,
      required: true,
    },
    showCircle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fetchedAddress: {
        address: '',
        perioxh: '',
        postalCode: '',
        countryTitle: '',
      },
      fetchNewCoords: null,
      animation: 2,
      minRadius: 0,
      maxRadius: 20000,
      serviceRadius: 0,
    }
  },
  computed: {
    center() {
      return {
        lat: this.lat,
        lng: this.lng,
      }
    },

    watchFor() {
      return [
        'address',
        'perioxh',
        'postalCode',
        'country',
      ]
    },

    circleOptions() {
      return {
        strokeColor: '#A7844E',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#A7844E',
        fillOpacity: 0.35,
      }
    },
  },
  watch: {
    entity: {
      handler(v) {
        let update = false

        this.watchFor.forEach(property => {
          if (v[property] !== this.fetchedAddress[property]) {
            clearTimeout(this.fetchNewCoords)
            update = true
          }
        })

        if (update) {
          this.fetchNewCoords = setTimeout(() => {
            this.fetchCoords()
          }, 500)
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchCoords()
    if (this.entityRadius > 0) {
      this.serviceRadius = this.entityRadius
    }
  },
  mounted() {
    this.$store.dispatch('app-common/fetchMainParameters')
      .then(response => {
        const mainParams = response.data.content
        this.minRadius = parseInt(mainParams.find(param => param.id === 1).value, 10)
        this.maxRadius = parseInt(mainParams.find(param => param.id === 2).value, 10)
        if (this.entityRadius === 0) {
          this.serviceRadius = parseInt(mainParams.find(param => param.id === 3).value, 10)
        }
      })
  },
  methods: {
    fetchCoords() {
      this.fetchedAddress = {
        address: this.entity.address,
        perioxh: this.entity.perioxh,
        postalCode: this.entity.postalCode,
        countryTitle: this.entity.country.title,
      }

      this.animation = 1

      this.$geocoder.send({
        address_line_1: this.fetchedAddress.address,
        address_line_2: '',
        city: this.fetchedAddress.perioxh,
        state: '',
        zip_code: this.fetchedAddress.postalCode,
        country: this.fetchedAddress.countryTitle,
      }, res => {
        const [place] = res.results
        if (place) {
          this.$emit('update:lat', place.geometry.location.lat)
          this.$emit('update:lng', place.geometry.location.lng)

          this.animation = 2
        }
      })
    },

    radiusAllowed(num) {
      if (num >= this.minRadius && num <= this.maxRadius) {
        return true
      }
      this.serviceRadius = this.maxRadius
      this.$emit('update:entityRadius', num)
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Μη επιτρεπόμενη ακτίνα εξυπηρέτησης',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      })
      return false
    },

    setRadius(e) {
      const num = parseInt(e, 10)
      if (this.radiusAllowed(num)) {
        this.$refs.circle.$circleObject.setRadius(num)
      }
    },

    updateRadius(e) {
      const num = parseInt(e, 10)
      if (this.radiusAllowed(num)) {
        this.serviceRadius = num
        this.$emit('update:entityRadius', num)
      }
    },
  },
}
</script>

<style lang="scss">
.vue-map-container {
  height: 350px;
}
</style>
