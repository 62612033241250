<template>
  <b-media vertical-align="center">
    <template #aside>
      <b-avatar :src="logo" :text="avatarText(title)" size="32" />
    </template>
    <b-link :href="(url) ? `${eshopUrl}${baseUrl}/${url}` : ''" target="_blank">
      <span class="font-weight-bold d-block text-nowrap">
        {{ title }}
      </span>
      <small class="text-muted">{{ subtitle }}</small>
    </b-link>
  </b-media>
</template>

<script>
import { BLink, BMedia, BAvatar } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BMedia,
    BAvatar,
    BLink,
  },
  props: {
    logo: {
      type: [String],
      required: false,
      default: '',
    },
    url: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    baseUrl: {
      type: String,
      required: false,
      default: 'seller',
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed: {
    eshopUrl() {
      return process.env.VUE_APP_SHOP_URL
    },
  },
}
</script>
