<template>
  <div>
    <div class="d-flex">
      <feather-icon v-if="icon" :icon="icon" size="19" />
      <h4 v-if="title" class="mb-0 ml-50">
        {{ title }}
      </h4>
    </div>
    <b-row v-if="inited">
      <b-col
        v-for="(formField, index) in formFields"
        :key="formField.name ? formField.name : formField.id"
        :md="formField.colSize ? formField.colSize : 4"
        :class="formField.hidden ? 'hidden' : 'mt-1'"
        cols="12"
      >
        <MapWrapper
          v-if="
            formField.type === 'map' || formField.type === 'area-selection-map'
          "
          :lat.sync="entity.lat"
          :lng.sync="entity.lng"
          :entity-radius.sync="entity.serviceRadius"
          :entity="formField.parent ? entity[formField.parent] : entity"
          :show-circle="formField.type === 'area-selection-map'"
        />
        <FormField
          v-else-if="!formField.hidden"
          :form-field="formField"
          :module="module"
          :entity="formField.parent ? entity[formField.parent] : entity"
          :autofocus="allowAutofocus && index == 0"
          :checkbox-click="handleCheckboxClick"
          :append-to-body="true"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FormField from '@/layouts/entity/FormField.vue'
import MapWrapper from '@/layouts/entity/MapWrapper.vue'

export default {
  components: {
    BRow,
    BCol,
    FormField,
    MapWrapper,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
      required: false,
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    formFields: {
      type: Array,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    allowAutofocus: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      blankEntity: {},
      inited: false,
    }
  },
  mounted() {
    this.initBlankEntity()
    this.inited = true

    this.$store.commit('app-common/entity', {
      type: this.module,
      item: JSON.parse(JSON.stringify(this.entity)),
    })
  },
  methods: {
    initBlankEntity() {
      if (!('id' in this.entity)) {
        this.formFields.forEach((field) => {
          let newVal = ''
          if (field.type === 'select' || field.type === 'selectmultiple') {
            newVal = null
          } else if (
            field.type === 'checkbox' ||
            field.type === 'checkboxgroup'
          ) {
            newVal = false
          }
          if (field.parent) {
            if (!(field.parent in this.blankEntity)) {
              this.blankEntity[field.parent] = {}
            }
            this.blankEntity[field.parent][field.id] = newVal
          } else {
            this.blankEntity[field.id] = newVal
          }
        })
        this.$parent.entity = this.blankEntity
      }
    },

    initForm(formFields) {
      formFields.forEach((formField) => {
        if (formField.type === 'checkbox') {
          this.handleCheckboxClick(formField.id)
        }
      })
    },

    handleCheckboxClick(formFieldId) {
      if (!this.entity[formFieldId]) {
        this.formFields.forEach((formField) => {
          if (formField.dependency === formFieldId) {
            this.entity[formField.id] = null
          }
        })
      }
    },
  },
}
</script>
